import PropTypes from "prop-types"
import React from "react"
import Grid from "@material-ui/core/Grid"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Image from "./image"
import { Link ,navigate} from "gatsby"

interface Props {
  companyName: string
}

const useStyles = makeStyles(theme => ({
  appBar: {
    color: "#233348",
    backgroundColor: "#FFF",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  drawerList: {
    width: 250,
  },
  drawerToggle: {
    padding: 20,
  },
}))

const Header = ({ companyName }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (to) => {
    navigate(to)
    setAnchorEl(null);
  };
  const classes = useStyles()


  return (
    <React.Fragment>
      <Grid
        container
        style={{
          margin: `0 auto`,
          maxWidth: 960,
        }}
        component="nav"
      >
        <AppBar position="static" elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Typography
              color="inherit"
              className={classes.toolbarTitle}>
              <div style={{ width: 160, padding: 10 }}>
                <Link to="/">
                  <Image alt="elastic team logo" filename="elastic-team-logo.png" />
                </Link>


              </div>

            </Typography>

            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              Our  Expertise
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={()=>{handleNavigate('/cloud-application-engineers')}}>Cloud Engineers</MenuItem>
              <MenuItem onClick={()=>{handleNavigate('/hipaa-compliant-app-developers')}}>HIPAA Developers </MenuItem>
              <MenuItem onClick={()=>{handleNavigate('/pci-compliance-development-specialists')}}>PCI Developers</MenuItem>
              <MenuItem onClick={()=>{handleNavigate('/ecommerce-software-developers')}}>E-commerce Developers</MenuItem>
              <MenuItem onClick={()=>{handleNavigate('/blockchain-nft-developers')}}>Block-chain Developers</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Grid>
    </React.Fragment>
  )
}

Header.propTypes = {
  companyName: PropTypes.string,
}

Header.defaultProps = {
  companyName: `elastic team`,
}

export default Header
