import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Image from "./image"


const Footer = () => {
  const footerLinks = [
    {
      title: "Company",
      items: [
        { displayText: "About", url: "" },
        { displayText: "Hire a Team", url: "" },
        { displayText: "Blog", url: "" },
      ],
    },
    {
      title: "Regions",
      items: [
        { displayText: "North America", url: "" },
        { displayText: "Central America", url: "" },
        { displayText: "South America", url: "" },
      ],
    },
    {
      title: "Help",
      items: [
        { displayText: "Help Center", url: "" },
        { displayText: "Contact Support", url: "" },
        { displayText: "Instructions", url: "" },
      ],
    },
  ]

  return (
    <React.Fragment>
      <Box className="footer" component="footer">
        <Grid
          container
          style={{
            margin: `0 auto`,
            padding: 20,
            maxWidth: 960,
            color: "#ccc",
          }}
        >
          <Grid
            xs={12}
            sm={2} 
    
          >
            <Typography variant="h4" color="inherit">
            <div style={{ width: 60}}>
                <Image alt="elastic team logo" filename="elastic-team-icon-black.jpg" />
              </div>
            </Typography>
          </Grid>

          <Grid
            xs={12}
            sm={10}
            style={{ paddingTop: 10 }}
          >
            <Typography variant="body2" color="inherit">
             elastic team provides dedicated software development experts to help your company deliver software faster.
            </Typography>
            <Typography variant="body2" color="inherit">
              © elastic.team 2022. All rights reserved
            </Typography>
          </Grid>


        </Grid>
      </Box>
    </React.Fragment>
  )
}
export default Footer
